/// <reference path="../../../typings/libraries.d.ts"/>
/// <reference path="./idle-timeout.ts"/>

// TODO: Componentize this guy.
Alkami.Dom.onDocumentReady(() => {
   let sessionDialogCountdown: number;

   const isDebug = !!window.debugMode;
   const isPublic = !Alkami.Globals.isAuthenticated;
   const isRegistration = window.location.pathname.includes('/Registration');
   const isAuthentication = window.location.pathname.includes('/Authentication');
   const pingUrl = isDebug || isPublic ? '/Client/Ping' : '/Client/CheckSessionAlive';
   const logoutUrl = Alkami.Helpers.isMobileUrl() ? '/Mobile/Logout' : '/Logout';
   const isNativeTablet = Alkami.Helpers.isNativeTabletApplication();
   const isNative = Alkami.Helpers.isNativeApplication();

   //Native doesn't need to ping on public pages
   if ((isPublic || isDebug) && (isNative || isNativeTablet)) {
      return;
   }

   // Heartbeat for session keep alive.
   setInterval(function () {
      fetch(pingUrl, { credentials: 'same-origin' })
         .then((response) => response.text())
         .then((text) => {
            if (text === 'OK') { return Promise.resolve(text); }

            return Promise.reject(text);
         }).catch((e) => {
            if (isAuthentication) { return; }  // If services go down, we don't want a recursive refresh
            window.location.href = logoutUrl + '?idle=true';
         });
   }, 1000 * 60);

   // Don't do idle timeout in debug/public mode except for registration
   if (isDebug || (isPublic && !isRegistration)) {
      return;
   }

   // Don't do idle timeout if on a native application. They have their own mechanism
   if (isNativeTablet || isNative) {
      return;
   }


   // Build the Modal
   const localizedSessionDialog = document.querySelector('.localization.sessionDialog') as HTMLElement;
   let sessionBodyMarkup = '<div><p>You have been inactive for a while. You will be logged out in <span id="session-timeout-countdown" style="font-weight:bold"></span> seconds.</p></div>';
   let stillHereButtonText = 'I\'m still here!';
   let logoutButtonText = 'Logout';
   let modalTitleText = 'Are you still there?';

   if (localizedSessionDialog) {
      const dataset = localizedSessionDialog.dataset;

      sessionBodyMarkup = dataset.bodyContent || sessionBodyMarkup;
      stillHereButtonText = dataset.stillHereButtonText || stillHereButtonText;
      logoutButtonText = dataset.logoutButtonText || logoutButtonText;
      modalTitleText = dataset.sessionModalTitle || modalTitleText;
   }

   const sessionPrompt = Alkami.Helpers.createDialog({
      id: 'session-dialog',
      title: modalTitleText,
      content: sessionBodyMarkup,
      handlers: {
         'logout': function (e) {
            e.target.innerHTML = 'Please wait...';
            window.location.href = logoutUrl;
         },
         'cancel': function (e) {
            window.clearInterval(window.sessionDialogCountdown);
            idleTimeout.resetTimer();
         }
      },
      buttons: [{
         text: logoutButtonText,
         type: 'secondary',
         handlerName: 'logout',
         closePrompt: false
      }, {
         text: stillHereButtonText,
         handlerName: 'cancel',
         closePrompt: true
      }]
   });

   // Idle timer initialize
   const idleElement = document.documentElement;
   const idleTimeout = new IdleTimeout(idleElement, {
      delay: window.idleLogoutMinutes * 1000 * 60, // to milliseconds
      keepAliveEvents: ['keydown', 'mousedown'],
      pollDelay: 1000 * 60 // every minute in milliseconds
   });

   const expireHandler = () => {
      let counter = 30;

      const sessionTimeoutCountdownElement = document.getElementById('session-timeout-countdown');
      const updateCountdown = () => {
         if (sessionTimeoutCountdownElement) {
            sessionTimeoutCountdownElement.innerHTML = counter.toString();
         }
      }

      updateCountdown();
      sessionPrompt.open = true;

      // start countdown in dialog
      window.sessionDialogCountdown = window.setInterval(function () {
         let delayWindowMS = idleTimeout.delay + 30 * 1000; //Adding 30 seconds to the delay window for countdown
         let currentIdleWindowMS = Date.now() - idleTimeout.startTime;
         counter = Math.ceil((delayWindowMS - currentIdleWindowMS) / 1000);
         updateCountdown();

         if (counter <= 0) {
            window.clearInterval(window.sessionDialogCountdown);
            window.location.href = logoutUrl + '?idle=true';
         }
      }, 1000);
   };

   idleElement.addEventListener('idletimeout-expire', expireHandler);
});
